import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="perfil-circular.png" className="App-logo" alt="logo" />
        <h1>¡Bienvenid@!</h1>
        Este sitio esta en construcción, pero te dejo mis redes sociales:
        
        <a
          className="App-link"
          href="https://www.tiktok.com/@miguelowsky"
          target="_blank"
          rel="noopener noreferrer"
        >
          TikTok
        </a>
        <a
          className="App-link"
          href="https://www.instagram.com/el_miguelowsky/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
      </header>
    </div>
  );
}

export default App;
